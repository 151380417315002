import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

export default class PortfolioTile extends React.Component {

  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
  }

  state = {
    inFocus: 0,
  }

  toggleMouseEnter = event => {
    let status = 1
    this.setState({
      inFocus: status,
    })
  }

  toggleMouseLeave = event => {
    let status = 0
    this.setState({
      inFocus: status,
    })
  }

  handleLoad() {
    // $("myclass") //  $ is available here
    this.setState({
      inFocus: 0,
    })
  }

  render() {

    return (
      <div
        className="tile is-parent is-info p-1"
        style={{display: "flex", flexDirection: "row", alignItems: "center", alignItems: "flex-end",  flexWrap: "wrap", justifyContent:"center", flexShrink:0, flexGrow: 0, flexBasis:1}}
      >
        <article className="tile is-info">

          <div className="container ">

            {/* Add a tile header if its in the md file */}
            <div style={{position: "relative", zIndex: 2, top: "20px", height:"0px"}} className="p-0 " > 
              <span className={this.props.post ?.frontmatter ?.tileHeader ? "tag is-rounded has-background-success has-text-danger" : ""}>
                <h3 >{this.props.post ?.frontmatter ?.tileHeader }</h3>
              </span>
            </div>

            <div onMouseLeave={this.toggleMouseLeave} onMouseEnter={this.toggleMouseEnter}>

              <Link to={this.props.post ?.fields ?.slug} itemProp="url">

                {this.state.inFocus === 0 && (
                  <BackgroundImage
                    Tag="section"
                    className="portfolioitem"
                    fluid={this.props.post ?.mainImage ?.childImageSharp ?.fluid}
                    style={{
                      minWidth: "400px",
                      maxWidth: "500px",
                      quality: 100,
                      backgroundPosition: "center",
                      minHeight: "250px",

                    }}
                  >
                  </BackgroundImage>
                )}

                {this.state.inFocus === 1 && (
                  <div>

                    <div className="tileInvert" style={{
                      backgroundColor: "#010C13",
                      minWidth: "400px",
                      maxWidth: "500px",
                      minHeight: "250px",

                    }}
                    >

                      <div className="hero pt-5">
                        <div className="hero-body " >
                          <div className="container has-text-centered" style={{verticalAlign: "middle"}}>

                            <h3 className="title is-3 has-text-success 	">
                              {this.props.post ?.frontmatter.brand}
                            </h3>
                            <h5 className="title is-5 has-text-success	">
                              {this.props.post ?.frontmatter.campaign}
                            </h5>
                        </div>
                        </div>
                      </div>

                    </div>



                  </div>
                )}
              </Link>
            </div>
          </div>

        </article>
      </div>
    )
  }
}
