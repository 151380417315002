import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import PortfolioTileRow from "../components/portfolioTileRow"
import PortfolioTile from "../components/portfolioTile"


import "../mystyles.scss"

const Portfolio = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  const images = data.allFile.nodes
  const postsArray = [];

  //Helper function to attach images to post
  for (var i = 0; i < images.length; i++) {

    let img = images[i]
    
    let postIndex = posts.findIndex(j => j.frontmatter.mainImageName === img.name)
    if (postIndex >= 0) {
      posts[postIndex].mainImage = img
    }

    // Get the BW image
    postIndex = posts.findIndex(j => j.frontmatter.mainImageNameBW === img.name)
    if (postIndex >= 0) {
      posts[postIndex].mainImageBW = img
    }

  }

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Portfolio" />
        <Bio />
        <p>No articles found.</p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
    <div className="container is-fullhd has-text-centered">

      {/* <div className="title is-primary">The Work</div> */}

      <SEO title="Portfolio" />              


              <div className="p-0" style={{gap: "0px", display: "flex", flexDirection: "row", alignItems: "center", alignItems: "flex-end",  flexWrap: "wrap", justifyContent:"center", flexShrink:0, flexGrow: 0, flexBasis:1}}>
                {posts.map((post, index) => {
                  return (
                    <PortfolioTile post={post} key={index} />
                  )
                })}
              </div>


      </div>
    </Layout>
  )
}

export default Portfolio

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___order], order: ASC }) {
      nodes {
        excerpt
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          brand
          campaign
          description
          mainImageName
          mainImageNameBW
          order
          tileHeader
        }
        fields {
          slug
        }
      }
    }
    allFile(filter: { dir: { regex: "/blog//" }, extension: { ne: "md" } }) {
      nodes {
        name
        publicURL
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
